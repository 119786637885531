<template>
  <v-container fluid>
    <!-- search and other options  -->
    <!-- <Breadcrumbs /> -->
    <v-card class="mx-md-4">
      <v-card-text>
        <v-row class="pa-0 ma-0">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-layout row class="my-2">
                <v-flex xs12 sm4 md4 style="text-align: center; padding: 5px">
                  <v-select
                    v-model="selectedUser"
                    :items="allUsers"
                    item-text="name"
                    item-value="id"
                    label="Members"
                    solo-inverted
                    flat
                    dense
                    class=" mt-2"
                    hide-details="true"
                    @change="refresh"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4 md4 style="text-align: center; padding: 5px">
                  <v-select
                    v-model="selectedAction"
                    :items="actions"
                    item-text="name"
                    item-value="value"
                    label="Actions"
                    solo-inverted
                    flat
                    dense
                    class=" mt-2"
                    hide-details="true"
                    @change="refresh"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4 md4 style="text-align: center; padding: 5px">
                  <v-select
                    v-model="selectedModel"
                    :items="models"
                    item-text="name"
                    item-value="value"
                    label="Module"
                    class=" mt-2"
                    hide-details="true"
                    solo-inverted
                    flat
                    dense
                    @change="refresh"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row class="my-2">
                <v-flex xs12 sm4 md4 style="text-align: center; padding: 5px">
                  <v-text-field
                    v-model="dateRange"
                    label="Date Range"
                    prepend-inner-icon="event"
                    class=" mt-2"
                    hide-details="true"
                    solo-inverted
                    flat
                    readonly
                    dense
                    v-on="on"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4 style="padding: 12px">
                  <v-btn
                    color="primarygrad white--text"
                    style="margin-top: 1px"
                    block
                    @click="pagination"
                    >Go</v-btn
                  >
                </v-flex>

                <v-flex xs12 sm4 md4 style="padding: 12px">
                  <v-btn style="margin-top: 1px" block @click="exportAudit"
                    >Export</v-btn
                  >
                </v-flex>
              </v-layout>
            </template>

            <v-date-picker v-model="dates" color="primarygrad" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(dates)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Timeline Card -->
    <v-card v-if="allAudits.length > 0" class="secondarygrad mx-md-4 my-5 pa-3">
      <v-timeline dense clipped>
        <v-timeline-item
          v-for="(item, i) in allAudits"
          :key="i"
          color="primarygrad"
          small
        >
          <v-card class="">
            <v-col>
              <div style="font-size: 0.8rem">
                {{ auditText(item) }}
              </div>
              <div class="caption">
                On {{ item.createdOn | moment("MMMM Do YYYY, h:mm:ss a") }}
              </div>
            </v-col>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <!-- Pagination -->
      <v-divider style="margin-bottom: 10px"></v-divider>
      <v-card-text>
        <div class="v-data-footer">
          <v-spacer></v-spacer>
          <div class="v-data-footer__select">
            Rows per page:
            <div
              class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
            >
              <v-select
                v-model="records"
                :items="recordsPerPage"
                style="margin: 5px"
                @change="pagination"
              ></v-select>
            </div>
          </div>
          <div class="v-data-footer__select">
            <div class="v-data-footer__icons-before">
              <v-btn
                :disabled="page == 1 ? true : false"
                color="primary"
                fab
                small
                text
                @click="prevSet"
              >
                <v-icon :size="24">arrow_left</v-icon>
              </v-btn>
            </div>
            <div class="v-data-footer__pagination">
              {{ `${page}/${pageCount} of ${totalRecords}` }}
            </div>
            <div class="v-data-footer__icons-after">
              <v-btn
                :disabled="page >= pageCount ? true : false"
                color="primary"
                fab
                small
                text
                @click="nextSet"
              >
                <v-icon :size="24">arrow_right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else elevation="0">
      <v-card-text>
        <v-container
          fill-height
          fill-width
          style="text-align: center; width: 100%; display: inline"
        >
          <div style="text-align: center" align="center">
            <!-- <v-icon size="100">phone_disabled</v-icon> -->
            <div align="center">
              <v-img
                :src="noListContact"
                style="width: 200px; justify-content: center"
              ></v-img>
            </div>
            <br />
            <h3>Does not have any Audit History</h3>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import moment from "moment-timezone";
const _ = require("lodash");
import Papa from "papaparse";
import Breadcrumbs from "../components/breadcrumbs.vue";
import Blob from "blob";
import fb from "../js/firebase";

export default {
  data() {
    return {
      allAudits: [],
      search: "",
      // pagination structure
      recordsPerPage: [5, 15, 30, 60, 120],
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 5,
      allUsers: [],
      selectedUser: "",
      dates: [
        moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      modal: false,
      selectedModel: "all",
      models: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "List",
          value: "list",
        },
        {
          name: "Member",
          value: "accounts",
        },

        {
          name: "Plan",
          value: "plan",
        },
        {
          name: "Message Template",
          value: "template",
        },
        {
          name: "Dispositions",
          value: "dispo",
        },
        {
          name: "Custom Fields",
          value: "fields",
        },
        {
          name: "Team",
          value: "team",
        },
        {
          name: "Organization",
          value: "company",
        },
      ],
      actions: [
        { name: "Added", value: "added" },
        { name: "Updated", value: "updated" },
        { name: "Deleted", value: "deleted" },
        { name: "Cleared", value: "cleared" },
        { name: "Login", value: "login" },
        { name: "All", value: "all" },
      ],
      selectedAction: "all",
    };
  },

  watch: {
    async selectedTeam() {
      await this.refresh(); // get accounts and lists and dispos
      // await this.pagination(); // get the logs and load charts
    },
  },
  async mounted() {
    try {
      // Paginate
      var routeName = this.$router.currentRoute.name
        .toString()
        .trim()
        .replace(/ /g, "")
        .toLowerCase();

      if (this.paginate[routeName]) {
        this.page = this.paginate[routeName].page;
        (this.pageCount = this.paginate[routeName].pageCount),
          (this.totalRecords = this.paginate[routeName].totalRecords),
          (this.records = this.paginate[routeName].records);
      }
      await this.refresh();
    } catch (e) {
      console.log(e);
    }
  },
  async created() {
    // await this.refresh();
    // await this.getTotalAudit();
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
    noListContact() {
      return require("../assets/noListContact.png");
    },
    teams() {
      return this.$store.getters.teams;
    },
    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },
    dateRange() {
      return this.dates.join(" - ");
    },
    paginate() {
      return this.$store.getters.paginate;
    },
  },
  methods: {
    async exportAudit() {
      var exportData = [];
      this.loader = true;
      const self = this;

      try {
        if (limit === undefined || skip === undefined) {
          limit = 5;
          skip = 0;
        }

        var users = this.getUserIds();

        var start = this.dates[0];
        var end = this.dates[1] == undefined ? start : this.dates[1];
        if (start > end) {
          var temp = start;
          start = end;
          end = temp;
        }

        start = `${moment(start).format("YYYY-MM-DDT00:00:01")}`;
        end = `${moment(end).format("YYYY-MM-DDT23:59:59")}`;

        //Max no of contact in each query
        var maxCount = 5000,
          data = [],
          res;

        var loop = self.totalRecords / maxCount;
        var limit = 0,
          skip = 0;
        while (loop > 0) {
          skip += limit;
          limit = maxCount;

          res = await this.$http.post(`${this.ENDPOINT}/audits/fetch`, {
            users: users,
            action: this.selectedAction,
            model: this.selectedModel,
            start: start,
            end: end,
            limit: limit,
            skip: skip,
          });

          data = data.concat(res.body.data);
          loop--;
        }

        exportData = _.orderBy(data, "time", "desc");
      } catch (e) {
        console.log(e);
        this.loader = false;

        return this.$swal({
          type: "error",
          text: "Connection error. Try again later.",
        });
      }

      if (exportData.length < 1) {
        this.loader = false;
        this.$swal({ tyep: "warning", text: "No records to export" });
        return;
      }
      exportData = _.map(exportData, (d) => {
        var y = {};

        if (typeof d.targetModel != "undefined") {
          y.Module = d.targetModel;
        } else {
          y.Module = "";
        }

        if (typeof d.action != "undefined") {
          y.Action = d.action;
        } else {
          y.Action = "";
        }

        if (typeof d.actor != "undefined") {
          y.Member = d.actor.name;
        } else {
          y.Member = "";
        }

        if (typeof d.target != "undefined") {
          y.TargetName = d.target.name;
        } else if (typeof d.title != "undefined") {
          y.TargetName = d.target.title;
        } else {
          y.TargetName = "";
        }

        if (d.createdOn) {
          y.Date = this.$moment(d.createdOn).format("YYYY-MM-DD");
          y.Time = this.$moment(d.createdOn).format("hh:mm a");
        } else {
          y.Date = "";
          y.Time = "";
        }

        // Add totalContactsRemoved if it exists in the details
        if (
          d.details &&
          typeof d.details.totalContactsRemoved !== "undefined"
        ) {
          y.TotalContactsRemoved = d.details.totalContactsRemoved;
        } else {
          y.TotalContactsRemoved = "";
        }

        return y;
      });

      var csv = Papa.unparse(exportData);
      var csvData = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(
          csvData,
          `report_${new Date().getTime()}.csv`
        );
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        `audit_report_${new Date().getTime()}.csv`
      );
      tempLink.click();

      this.loader = false;

      fb.log("audit_export");
    },
    withTitle(item) {
      if (typeof item.actor != "undefined") {
        return (
          this.removeSfromString(item.targetModel) +
          " " +
          item.target.name +
          " " +
          item.action +
          " by " +
          item.actor.name
        );
      } else {
        return (
          this.removeSfromString(item.targetModel) +
          " " +
          item.target.name +
          " " +
          item.action
        );
      }
    },

    withName(item) {
      if (typeof item.actor != "undefined") {
        return (
          this.removeSfromString(item.targetModel) +
          " " +
          item.target.name +
          " " +
          item.action
        );
      } else {
        return (
          this.removeSfromString(item.targetModel) +
          " " +
          item.target.name +
          " " +
          item.action
        );
      }
    },
    auditText(item) {
      var auditText = "";

      auditText =
        item.targetModel.charAt(0).toUpperCase() +
        item.targetModel.slice(1) +
        " ";

      if (item.target.name || item.target.title) {
        auditText +=
          item.target && item.target.name
            ? item.target.name
            : item.target.title;
      }

      auditText += " " + item.action;

      if (typeof item.actor != "undefined") {
        auditText += " by " + item.actor.name;
      }

      if (typeof item.target.ip != "undefined") {
        auditText += " from IP: " + item.target.ip;
      }

      // Add totalContactsRemoved if available
      if (
        item.details &&
        typeof item.details.totalContactsRemoved != "undefined"
      ) {
        auditText +=
          " (Total Contacts Removed: " +
          item.details.totalContactsRemoved +
          ")";
      }

      return auditText;
    },
    // Init Page
    async refresh() {
      try {
        await this.fetchAccounts();
        await this.getTotalAudit();
        if (this.page < 1 || this.page > this.pageCount) {
          this.page = 1;
        }
        this.allAudits = [];
        this.allAudits = await this.getAuditDetails(
          this.records,
          this.records * this.page - this.records
        );
      } catch (e) {
        // error
        console.log("error in refresh", e);
      }
    },

    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },

    async fetchAccounts() {
      this.allUsers = [];
      // var teamIds = this.getTeamIds();

      try {
        var teams = [];
        teams = await this.getTeamIds();

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );
        this.allUsers = response.body;

        if (
          this.$store.state.user.role == "Manager" ||
          this.$store.state.user.role == "Teammanager"
        ) {
          this.allUsers.unshift({
            id: "allMembers",
            name: "All Members",
          });
        }

        if (this.selectedUser == "") {
          this.selectedUser =
            this.allUsers.length > 0 ? this.allUsers[0].id : "";
        }
      } catch (e) {
        if (e.body.error && e.body.error.message)
          return this.$swal({ type: "error", text: e.body.error.message });
        else
          return this.$swal({
            type: "error",
            text: "Error while fetching accounts",
          });
      }
    },
    getUserIds() {
      var users = [];

      if (
        this.selectedUser == "allMembers" &&
        this.$store.getters.user.role.toLowerCase() == "manager"
      ) {
        users = [];
      } else if (
        this.selectedUser == "allMembers" &&
        this.$store.getters.user.role.toLowerCase() == "teammanager"
      ) {
        users = _.map(this.allUsers, "id");
      } else {
        users.push(this.selectedUser);
      }

      users = _.without(users, "allMembers");
      return users;
    },
    removeSfromString(str) {
      if (str != null && str.length > 0 && str.charAt(str.length - 1) == "s") {
        str = str.substring(0, str.length - 1);
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      try {
        this.pageCount = Math.ceil(this.totalRecords / this.records);
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        this.allAudits = await this.getAuditDetails(
          this.records,
          this.records * this.page - this.records
        );

        await this.getTotalAudit();

        // Save default pagination of seperate pages
        this.paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = {
          page: this.page,
          pageCount: this.pageCount,
          records: this.records,
          totalRecords: this.totalRecords,
        };
        this.$store.commit("setPagination", this.paginate);
      } catch (e) {
        console.log(e);
      }
    },

    async getTotalAudit() {
      try {
        var users = this.getUserIds();

        var start = this.dates[0];
        var end = this.dates[1] == undefined ? start : this.dates[1];
        if (start > end) {
          var temp = start;
          start = end;
          end = temp;
        }

        start = `${moment(start).format("YYYY-MM-DDT00:00:01")}`;
        end = `${moment(end).format("YYYY-MM-DDT23:59:59")}`;

        var response = await this.$http.post(
          this.$store.state.ENDPOINT + "/audits/load-count",
          {
            users: users,
            action: this.selectedAction,
            model: this.selectedModel,
            start: start,
            end: end,
          }
        );
        this.totalRecords = response.body.count;
        this.pageCount = Math.ceil(this.totalRecords / this.records);

        return;
      } catch (err) {
        console.log(err);
      }
    },

    // get all the users by teamsid
    async getAuditDetails(limit, skip) {
      try {
        if (limit === undefined || skip === undefined) {
          limit = 5;
          skip = 0;
        }

        var users = this.getUserIds();

        var start = this.dates[0];
        var end = this.dates[1] == undefined ? start : this.dates[1];
        if (start > end) {
          var temp = start;
          start = end;
          end = temp;
        }

        start = `${moment(start).format("YYYY-MM-DDT00:00:01")}`;
        end = `${moment(end).format("YYYY-MM-DDT23:59:59")}`;

        var response = await this.$http.post(
          this.$store.state.ENDPOINT + "/audits/fetch",
          {
            users: users,
            action: this.selectedAction,
            model: this.selectedModel,
            start: start,
            end: end,
            limit: limit,
            skip: skip,
          }
        );
        response = response.body;

        response.data = _.filter(
          response.data,
          (item) => item.accountsId !== "5e9160cbcf2c3543a1228ea0"
        );

        response.data = _.filter(
          response.data,
          (item) => item.target.id !== "5e9160cbcf2c3543a1228ea0"
        );

        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.v-select .v-select__selections {
  padding: 12px 0 !important;
}
</style>
